:root{
    --cat-width: 25vw;
    --cat-height: 35vw;

}

@media (max-width: 767px){
:root{
    --cat-width: 40vw;
    --cat-height: 52vw;
}

}

@media (min-aspect-ratio: 180/100) {
    :root{
        --cat-height: 25vw;
        --cat-width: 18vw;
    }
}

.categories{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-left:5vw;
    padding-right:5vw;
    -webkit-overflow-scrolling: touch;
    height: var(--cat-height);
} 

.categories-no-text{
    height: var(--cat-width);
    overflow-y: hidden;
}

.categories-no-text .category {
    display:inline-block;
    width: var(--cat-width);
    height: var(--cat-width);
    padding-left: 2vw;
}

.categories .category {
    display:inline-block;
    width: var(--cat-width);
    height: var(--cat-height);
    padding-left: 2vw;
}
.categories .category .category-image{
    width: 100%;
    margin: auto;
}
.categories .category .category-image img{
    width: var(--cat-width);
    height: var(--cat-width);
    object-fit: cover;

}

.categories .scroll-control{
    font-size: var(--font-vlarge);
    font-family: roboto-regular;
    color: var(--color-yellow);
    margin-top: 8vw;
    cursor: pointer;
    padding: 2vw;
}

#archives .scroll-control{
    color: var(--color-pewter);
}
.categories .scroll-right{
    position: absolute;
    z-index: 0;
    right: 0;
    top: static;
    
}

.categories .scroll-left{
    position: absolute;
    z-index: 0;
    left: 0;
    top: static;
    
}
.categories .category .category-name{
    margin-top: 2vw;
    font-family: roboto-regular;
    font-size: var(--font-mid);
    text-align:center;
}


.shop .shop-head{
    display: inline-grid;
    margin-top: 5vw;

    grid-template-columns: 50vw;
    height: 25vw;
    width: 100%;
}

.shop .shop-asshat{
    grid-column-start: 1;
    grid-row-start: 1;
    height: 40vw;
}
.shop .shop-asshat img{
    width:100%;
    height:100%;
    object-fit: cover;
}

.shop .new-arrivals{
    grid-column-start: 2;
    grid-row-start: 1;
    background-color: var(--color-pewter);
    width:100%;
    
}

.shop .new-arrivals .tag{
    width:100%;
    text-align: center;
    color: white;
    font-size: calc(var(--font-mid) * 1.2);
    font-family: roboto-bold;
    padding-top: 15vw;

}

.shop .new-arrivals .button{
    width:100%;
    text-align: center;
    cursor: pointer !important;

    
}
.shop .new-arrivals .button button{
    font-size: calc(var(--font-small) * 1.2);
    font-family: roboto-regular;
    border: 1px solid white;
    background-color:  var(--color-pewter);
    color: white;
    padding: 2vw;
    padding-left: 4vw;
    padding-right: 4vw;
    margin-top: 4vw;
}

#archives{
  
    padding-top: 5vw;
    margin-bottom: -15vw;
    background-image: url(../images/yellowhouse.svg);
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--color-yellow);
    height: 40vw;
}

@media (max-width: 767px){
    #archives{
        height: 60vw;
        margin-bottom: -20vw;
    }

    .shop .shop-head{
        height: 60vw;
    }
    .shop .shop-asshat{
        height: 60vw;
    }
}


@media (min-aspect-ratio: 180/100) {
    :root{
        --cat-height: 25vw;
        --cat-width: 18vw;
    }

    .shop .shop-head{
        display: inline-grid;
        margin-top: 5vw;
        grid-template-columns: 40vw;
        height: 20vw;
        width: 80vw;
        padding-left:10vw;
    }
    
    .shop .shop-asshat{
        grid-column-start: 1;
        grid-row-start: 1;
        height: 32vw;
    }
    .shop .shop-asshat img{
        width:100%;
        height:100%;
        object-fit: cover;
    }

    .shop .new-arrivals .tag{
  
        padding-top: 10vw;
    
    }
}    

