:root {
    --animation-factor: calc(0.2s);
}
@keyframes rotate {
    to {
        transform: rotate(32deg) scale(0.25, 0.185) translate(-12vw, -32vw);
        transform-origin: top left;
        padding-left: 15vw;
    }
}

@keyframes srink-fade {
    to {
        top: 4vw;
        font-size: var(--font-small);
        opacity: 0;
    }
}

@keyframes menu {
    to {
        height: 6vw;
        box-shadow: 0px 0px 4px black;
    }
}

@keyframes thatch {
    to {
        height: 3vw;
    }
}

@keyframes nav-menu {
    to {
        top: 3vw;
    }
}

.heading.active {
    height: 6vw;
}

.heading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 10;
    height: 55vw;
    animation: menu var(--animation-factor) linear 1;
    animation-delay: calc(var(--scroll) * -1s);
    animation-play-state: paused;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    box-shadow: none;
}

.heading-img {
    width: 33vw;
    padding: 4vw 7.5vw 0vw 7.5vw;
    pointer-events: none;
}

.heading-img svg {
    pointer-events: fill;
}

.heading .menu {
    pointer-events: all;
}

.heading.active .heading-img svg {
    transform: rotate(32deg) scale(0.25, 0.185) translate(-12vw, -32vw);
    transform-origin: top left;
    padding-left: 15vw;
}

.heading-img svg {
    position: relative;
    top: 0;
    left: 0;
    animation: rotate var(--animation-factor) linear 1;
    animation-delay: calc(var(--scroll) * -1s);
    animation-play-state: paused;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}

.heading.active .thatch {
    height: 3vw;
}

.heading .thatch {
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    height: 15vw;
    background-image: url(../images/thatch.svg);
    background-repeat: no-repeat;
    background-size: cover;

    animation: thatch var(--animation-factor) linear 1;
    animation-delay: calc(var(--scroll) * -1s);
    animation-play-state: paused;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}

.heading.active .menu {
    top: 3vw;
}

.heading .menu span img.home-img {
    width: 3.2vw;
}

.heading .tagline {
    position: fixed;
    top: 22vw;
    right: 0;
    padding-right: 4.5vw;
    width: 43vw;
    font-family: roboto-bold;
    font-size: var(--font-vlarge);
    padding-right: 2vw;
    padding-top: 2vw;

    animation: srink-fade var(--animation-factor) linear 1;
    animation-delay: calc(var(--scroll) * -1s);
    animation-play-state: paused;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}

.heading .menu .subscribe {
    cursor: pointer;
}

.heading .menu {
    z-index: 10;
    position: fixed;
    top: 16vw;
    left: 50%;

    animation: nav-menu var(--animation-factor) linear 1;
    animation-delay: calc(var(--scroll) * -1s);
    animation-play-state: paused;
    animation-fill-mode: both;
    animation-iteration-count: 1;
    padding-right: none;
}

@media (max-width: 767px) {
    .head {
        height: 25vw;
    }
    .heading,
    .heading.active {
        height: 25vw;
        animation: none;
        background-color: white;
    }
    .heading .menu {
        left: 0vw !important;
        top: 10vw !important;
        height: 7vw !important;
        width: 100vw;
        animation: none;
        background-color: white;
        padding-left: 3vw;
        padding-top: 3vw;
        padding-bottom: 2vw;
    }

    .heading .menu span {
        font-size: var(--font-large) !important;
    }

    .heading-img {
        width: 44vw;
    }
    .heading-img svg {
        position: absolute;
        left: 0vw;
        top: 0vw;
        animation: none;
        transform: rotate(32deg) scale(0.4, 0.3) translate(-12vw, -25vw);
        transform-origin: top left;
        padding-left: 15vw;
    }

    .heading.active .heading-img svg {
        transform: rotate(32deg) scale(0.4, 0.3) translate(-12vw, -25vw);
    }

    .heading-img svg {
        position: relative;
        top: 0;
        left: 0;
        animation: none;
    }
    .heading .thatch {
        height: 10vw !important;
        animation: none;
    }
    .home-img {
        display: none;
    }

    .heading .tagline {
        position: fixed;
        top: 22vw;
        left: 3vw;
        width: 94vw;
        font-family: roboto-bold;
        font-size: calc(var(--font-vlarge) * 2);
        text-align: right;
    }
}

.mini-cart {
    z-index: 101;
    display: none;
    position: fixed;
    bottom: 7vw;
    right: 3vw;
    height: 3.5vw;
    width: 3.5vw;
    z-index: 1;
    background-color: var(--color-yellow);
    border-radius: 50%;
    padding: 0 !important;
    margin-bottom: -5vw;

    -webkit-box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
        rgba(0, 0, 0, 0.2) 0px 12px 28px 0px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
        rgba(0, 0, 0, 0.2) 0px 12px 28px 0px;
}
#mini-cart-number {
    position: fixed;
    bottom: 4vw;
    right: 3vw;
    border-radius: 50%;
    background-color: var(--color-red);
    font-size: var(--font-vsmall);
    height: 2vw;
    width: 2vw;
    line-height: 2vw;
    text-align: center;
}

.mini-cart svg {
    color: var(--color-pewter);
    height: 2.5vw !important;
    width: auto !important;
    padding: 0.3vw !important;

    margin: 0 !important;
    margin-top: -0.2vw;
}

.ecwid-minicart-floating {
    display: none !important;
}

@media (max-width: 767px) {
    .mini-cart {
        height: 15vw;
        width: 15vw;

        bottom: 10vw;
        right: 3vw;
    }

    .mini-cart svg {
        height: 12.1vw !important;
        width: auto !important;
    }

    #mini-cart-number {
        position: fixed;
        bottom: 15vw;
        right: 3vw;
        border-radius: 50%;
        background-color: var(--color-red);
        font-size: var(--font-mid);
        height: 7vw;
        width: 7vw;
        line-height: calc(var(--font-mid) * 1.3);
        text-align: center;
    }
}
