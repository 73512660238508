.home{
    margin-top: 55vw;
}
.home .home-bottom{
    display: inline-grid;
    grid-template-columns: 50vw;
    margin-bottom: -15vw;
}

.home .home-bottom .about{
    grid-column-start: 1;
    background-image: url(../images/thatchrecerse.svg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 40vw;
    width:50vw;
    color: white;
    font-size: calc(var(--font-vlarge) * 1.5);
    font-family: roboto-bold;
    margin-left: 0;
}

.home .home-bottom .about-text{
    width:100%;
    height:100%;
    margin-left:15vw;
    padding-top:9vw;
}

.home  .home-bottom .about-nav{
    grid-column-start: 2;
    width:50vw;
    height: 40vw;
}

.home .home-bottom .about-nav .link-container{
    font-size: calc(var(--font-vlarge));
    padding-left:5vw;
    padding-top: 2vw;
}
.home .home-bottom a{
    display:block;
    color: var(--color-pewter);
    text-decoration: none;
    font-family: roboto-bold;
}

.home .home-bottom .about-nav .link-container a:hover{
    color: var(--color-yellow);
}

.home .home-bottom .about a{
    color: white;
}

.home .home-bottom .about a:hover{
    color: var(--color-pewter);
}


#instafeed {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: calc(2vw);
    grid-row-gap: calc(2vw);
    max-width: 100rem;
    margin-top: 5vw;
    margin-bottom: 5vw;
    align-content: center;
    margin-left: 4vw;
  }
  
  #instafeed a {
    display: block;
    width: 28vw;
    height: 28vw;
    overflow:hidden;
  }
  
  #instafeed img {
    display: block;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 767px){
      .home{
          margin-top: 85vw;
      }
  }
  
@media (min-aspect-ratio: 180/100) {
    #instafeed {
        width: 80%;
        margin: auto;
        margin-top: 5vw;
        margin-bottom: 5vw;
        grid-column-gap: calc(.5vw);
        grid-row-gap: calc(.5vw);
    }

    #instafeed a {
        display: block;
        width: 26.33vw;
        height: 24vw;
        overflow:hidden;
      }

  
}
