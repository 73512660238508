
.post{

    width: 100vw;
  }
  
  .post .subject{
    font-size: var(--font-mid)
  }
  
  .post .content{
    padding-top: 2vw;
  }
  
  .post .yellow_roof {
    background-image: url(../images/yellowhouse.svg);
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: var(--color-yellow);
  
  }

  
  
  
  .post .yellow_roof,
  .post .white_clean,
  .post .yellow_block,
  .post .large_grey
   {
    display: inline-grid;
    grid-template-columns: 5vw 45vw 3vw 42vw 5vw;
    grid-template-rows: 15vw;
    margin-top:none;
    padding-top:none;
    margin-bottom:4vw;
  
  }
  
  .post .yellow_block,
  .post .white_clean{
    grid-template-rows: 3vw auto 3vw;
  
  }
  
  .post .large_grey {
    grid-template-rows: 3vw   ;
  }
  
  .post .large_grey .subject{
    grid-column-start: 4;
    grid-row-start: 2;
  }
  
  
  
  .post .large_grey::before{
    content:"";
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row-start: 1;
    background-color: var(--color-pewter);
  }
  .post .large_grey::after{
    content:"";
    grid-column-start: 5;
    grid-column-end: 5;
    grid-row-start: 2;
    height:50%;
    background-color: var(--color-pewter);
  }
  
  .post .large_grey .utility{
    content:"";
    grid-column-start: 1;
    grid-row-start: 2;
    height: 50%;
    background-color: var(--color-pewter);
  
  }
  
  .post .large_grey .utility2{
    content:"";
    grid-column-start: 3;
    grid-row-start: 2;
    height: 50%;
    background-color: var(--color-pewter);
  
  }
  
  
  .post .yellow_block::before{
    content:"";
    grid-column-start: 3;
    grid-column-end: 6;
    grid-row-start: 1;
    background-color: var(--color-yellow);
  }
  .post .yellow_block::after{
    content:"";
    grid-column-start: 5;
    grid-column-end: 6;
    grid-row-start: 2;
    background-color: var(--color-yellow);
  }
  
  .post .yellow_block .utility{
    content:"";
    grid-column-start: 3;
    grid-row-start: 2;
    grid-row-end: 4;
    background-color: var(--color-yellow);
  
  }
  
  
  .post .yellow_block .utility2{
    content:"";
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 3;
    grid-row-end: 3;
    background-color: var(--color-yellow);
  
  }
  
  .post .yellow_roof .body,
  .post .yellow_block .body,
  .post .white_clean .body
  
  {
    grid-column-start: 2;
    grid-row-start: 2;
    padding-right: 2vw;
    
  }
  
  .post .large_grey .body{
    display: inline-grid;
    grid-column-start: 4;
    grid-row-start: 2;
  
    grid-template-columns: auto;
    grid-template-rows: 50% auto;
    margin-top:none;
    padding-top:none;
  
    align-items: bottom;
  
  }
  
  
  .post .yellow_roof .body.no-images,
  .post .white_clean .body.no-images,
  .post .yellow_block .body.no-images
  {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
    
  }
  .post .yellow_block .media-container{
    background-color: var(--color-yellow);
  }
  
  .post .large_grey .media-container{
    background: -webkit-linear-gradient(180deg, var(--color-pewter) 50%, white 50%);
  
    background: linear-gradient(180deg, var(--color-pewter) 50%, white 50%);
  
  
  }
  
  
  
  .post .large_grey .media-container{
    display: grid;
    align-items: center;
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
  
  }
  
  
  .post .large_grey .subject-post{
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    background-color: var(--color-pewter);
    color:white;
    display: flex;                   /* defines flexbox */
    flex-direction: column-reverse;          /* top to bottom */
    align-items: flex-end; 
    
  }
  
  .post .large_grey .extra-content{
    grid-column-start: 1;
    grid-row-start: 2;
  }
  
  .post .large_grey .post-details{
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
  }
  
  
  .post  .yellow_roof .media-container,
  .post .white_clean .media-container,
  .post .yellow_block .media-container
  
  {
  
    display:flex;
    flex-direction:column;
    justify-content:center;
    text-align:center;
    grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 2;
    height:100%;
    
  }
  
  .post  .yellow_roof .media-container iframe,
  .post  .white_clean .media-container iframe,
  .post  .yellow_block .media-container iframe,
  .post .large_grey .media-container iframe
  {
    width:100%;
    height:30vw;
  }
  
  .post  .yellow_roof .media-container img,
  .post  .white_clean .media-container img,
  .post  .yellow_block .media-container img,
  .post .large_grey .media-container img
  {
    width:100%;
    margin:auto;
  }
  
  
  
  .post .yellow_roof .extra-content,
  .post .white_clean .extra-content,
  .post .yellow_block .extra-content
   {
    display:none;
  
  }


  
@media (max-width: 767px){
  .post .yellow_roof {
    background-position: center;
  }

  .post .yellow_roof,
  .post .white_clean,
  .post .yellow_block,
  .post .large_grey{
    display: inline-block;
  }

  .post  .yellow_roof .media-container iframe,
  .post  .white_clean .media-container iframe,
  .post  .yellow_block .media-container iframe,
  .post .large_grey .media-container iframe
  {
    height:60vw;
  }

  .post {
    margin-top: 15vw;
  }

  .post .body{
    padding-left: 3vw;
    padding-right: 3vw;
  }

  .post .large_grey .body{
    display: inline-block;
    padding:0;
    margin:0;
  }
  .post .large_grey .post-details{
    padding-left:0;
    margin:0;
  }

  .post .large_grey .subject-post{
    padding-top: 10vw;
    padding-left:3vw;
    margin:0;
    text-align:left;
    align-items: flex-start;
  }

  .post .large_grey .media-container{
    margin-top: 3vw;
  }



}


@media (min-aspect-ratio: 180/100) {
  .post{
    width:80vw;
   
  }
  .post .yellow_roof,
  .post .white_clean,
  .post .yellow_block,
  .post .large_grey
   {
    grid-template-columns: 5vw 35vw 3vw 32vw 5vw;
    margin-left: 10vw;
  }
  
  
}
