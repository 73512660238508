
 .menu span{
    padding-right: 2.8vw;
    font-size: 2vw;
  }
  
   .menu span.last{
    padding-right: 2vw;
  }
  
   .menu a{
    color:black;
    text-decoration: none;
  }
   .menu span.active a{
    color:var(--color-yellow)
  }
  
  .menu{
    font-family: roboto-bold;
  }

