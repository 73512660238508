.foot {
    position: relative;
    z-index: 2;
    width:100%;
    background-image: url(../images/footerbg.svg);
    background-size: cover;
    height: 55vw;
}

.foot a,
.timify-button{
    color: white;
    cursor: pointer;
}

.foot .top-menu{
    width:100%;
    text-align: right;
    padding-top: 15vw;

}

.foot .subscribe {
    font-size: var(--font-mid);
    font-family: roboto-bold;
    
    margin-top: 2vw;
    border-bottom: solid white 3px;
    padding-bottom: 3px;
    width: 50vw;
    margin-left: 5vw;
    color: white;
    cursor: pointer;

}

.foot .subscribe img{
   height:var(--font-mid);
   color:white; 
   margin-bottom: -0.5vw
}

.foot .brand{
    margin-top: 4vw;
    font-size: var(--font-vlarge);
    font-family: roboto-bold;
    margin-left: 5vw;
    color: white;
}

.foot .bottom-menu{
    margin-left: 5vw;
    padding-top: 4vw;

}

@media (max-width: 767px){
    .foot {
        height: 75vw;
    }

    .foot .top-menu{
        padding-top: 20vw;
        margin-bottom: 7vw;
    }
    .foot .nav-item, .timify-button{
        font-size: var(--font-small) !important;
    }

}


/**
*** Timify style
*/
.ta-header__container{
    background-color: var(--color-pewter) !important;
}

