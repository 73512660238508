.about{
    background-image: url(../images/about_bg.svg) ;
    background-size: 100%;
    margin-top: 4vw;
}


.about .aboutbox
   {
    width: 86vw;
    min-height: 35vw;
    background-color: var(--color-pewter);
    display: inline-grid;
    grid-template-columns: 2vw 40vw 40vw 2vw;
    grid-template-rows: 8vw auto;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-top: 3vw;
    padding:2vw;
    color: white;
  }

.aboutbox .title{
    grid-row-start: 1;
    grid-column-start: 2;
    grid-column-end: 4;
    
    font-size: var(--font-large);
    font-family: 'roboto-bold';
    height: calc(var(--font-large) * 1.1);
    border-bottom: 4px solid white;
    width: 55vw;
}


.aboutbox .aboutbody{
    grid-row-start: 2;
    grid-column-start: 3;
    margin-bottom: 1vw;
}


.aboutbox .image{
    margin: auto;
    grid-row-start: 2;
    grid-column-start: 2;
    margin-top: 2vw;
    width:95%;
}

.aboutbox .image img{
    width: 100%;
}

@media (max-width: 767px){

.about .aboutbox{
    display: inline-block;
    margin-top: 24vw;
}

}

@media (min-aspect-ratio: 180/100) {
    .about{
        width: 80%;
        margin-left: 10%;
    }
    .about .aboutbox
   {
    width: 68.8vw;
    grid-template-columns: 2vw 30vw 30vw 2vw;

   }
}