

@font-face {
  font-family: 'roboto-regular';
  src: local('roboto-regular'), url(./fonts/Roboto-Regular.ttf) format('truetype');
}


@font-face {
  font-family: 'roboto-bold';
  src: local('roboto-bold'), url(./fonts/Roboto-Bold.ttf) format('truetype');
}

:root{
  --font-vsmall: 1vw;
  --font-small: 1.7vw;
  --font-mid: 3vw;
  --font-large: 5.5vw;
  --font-vlarge: 7.5vw;
  --color-red: #FF4035;
  --color-yellow: #F0D24B;
  --color-pewter: #414141;
}


@media (max-width: 767px){
  :root{
    --font-vsmall: 4vw;
    --font-small: 4vw;
    --font-mid: 5vw;
    --font-large: 5.5vw;
    --font-vlarge: 7.5vw;
  }
}



body{
  min-width:100%;
  font-family: 'roboto-regular';
  font-size: var(--font-small);
  color: var(--color-pewter);

}



@media (min-aspect-ratio: 180/100) {
  :root{
    --font-vsmall: .75vw;
    --font-small: 1.3vw;
    --font-mid: 2vw;
    --font-large: 5vw;
    --font-vlarge: 7vw;
  }
}

.red{
  color: var(--color-red);
}



.home{
  margin-top: 60vw;
}

.content {
  position: relative;
  margin-top:2vw;
}



h1 {
  font-size: var(--font-large);
  font-family: 'roboto-bold';
  border-bottom: 3px solid var(--color-pewter);
  margin-left: 9vw;
  margin-right: 40vw;
  padding-top: 2vw;
  text-transform: lowercase;
}

h2 {
  font-size: var(--font-mid);
  font-family: 'roboto-bold';
  border-bottom: 3px solid var(--color-pewter);
  margin-left: 9vw;
  margin-right: 40vw;
  padding-top: 2vw;
}

a{
  color: var(--color-pewter);
}


@media (max-width: 767px){
  .content{
    margin-top: 24vw;
  }

  h1, h2 {
    margin-left: 3vw;
    margin-right: 30vw;

  }
}



