html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--l
    .ec-store
    h1.ec-header-h1.page-title__name {
    font-size: var(--font-large);
    font-family: roboto-bold !important;
    border-bottom: 3px solid var(--color-pewter);
    margin-left: 9vw;
    margin-right: 40vw;
    padding-top: 2vw;
    text-transform: lowercase;
    font-weight: normal;
}

.css-jzut7o {
    display: none;
}

html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store .ec-breadcrumbs {
    display: none;
}

html#ecwid_html
    body#ecwid_body
    .ec-size:not(.ec-size--s)
    .ec-store
    .ec-breadcrumbs {
    display: none;
}

.ec-grid {
    padding-left: 5vw !important;
    padding-right: 5vw !important;
}

html#ecwid_html body#ecwid_body {
    font-family: roboto-regular !important;
}

html#ecwid_html body#ecwid_body .ecwid a,
html#ecwid_html body#ecwid_body .ecwid a:active,
html#ecwid_html body#ecwid_body .ecwid a:visited {
    font-family: roboto-regular !important;
}
html#ecwid_html body#ecwid_body .grid-product__title-inner {
    font-family: roboto-regular !important;

    font-size: var(--font-small) !important;
}

.grid-product__price-value .ec-price-item {
    font-family: roboto-regular !important;

    font-size: var(--font-small) !important;
}
html#ecwid_html body#ecwid_body .form-control {
    font-family: roboto-regular !important;
    color: var(--color-pewter) !important;
}

html#ecwid_html body#ecwid_body .ec-footer {
    display: none !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--m
    .ec-store
    .product-details--layout-sidebar-right
    .product-details__sidebar
    .product-details__product-title {
    font-size: var(--font-mid) !important;
    font-family: roboto-bold !important;
    border-bottom: 3px solid var(--color-pewter) !important;
    width: 100% !important;
    margin-left: 0 !important;
}
html#ecwid_html body#ecwid_body .details-product-share__title,
html#ecwid_html body#ecwid_body .ec-likely__wrapper {
    display: none !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--s
    .ec-store
    .product-details-module__content--collapsed {
    max-height: 100000vh !important;
}

html#ecwid_html body#ecwid_body .product-details-module__btn-more {
    display: none !important;
}

html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store .pager__head {
    font-size: var(--font-small) !important;
    font-family: roboto-regular !important;
    color: var(--color-pewter) !important;
    font-weight: normal !important;
}
html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--s
    .ec-store
    .pager__button--next,
html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store .pager__pages,
html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--s
    .ec-store
    .pager__button--prev {
    font-size: var(--font-small) !important;
    font-family: roboto-regular !important;
    color: var(--color-pewter) !important;
    font-weight: normal !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--l
    .ec-store
    .grid-sort__item.grid-sort__item--sortby {
    font-size: var(--font-small) !important;
    font-family: roboto-regular !important;
    color: var(--color-pewter) !important;
    font-weight: normal !important;
}

html#ecwid_html body#ecwid_body .ec-cart .ec-header-h1.page-title__name {
    font-size: var(--font-small) !important;
    font-family: roboto-regular !important;
    color: var(--color-pewter) !important;
    font-weight: normal !important;
    text-align: left !important;
    padding: 0 !important;
    margin: 0 !important;
}
html#ecwid_html body#ecwid_body .ec-size .ec-store {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--l
    .ec-store
    .product-details__product-description:not(.product-details__product-description--blank)
    .html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--l
    .ec-store
    .product-details-module,
html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store h6,
html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store .ec-header-h6 {
    font-size: var(--font-small) !important;
    font-family: roboto-regular !important;
}

html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store h4,
html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store .ec-header-h4 {
    font-size: var(--font-mid) !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--l
    .ec-store
    .details-product-price__value {
    font-size: var(--font-small) !important;
    font-family: roboto-regular !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--l
    .ec-store
    .product-details__product-sku {
    display: none;
}

html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .form-control--animated.form-control--done
    .form-control__button-text,
html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .form-control--animated.form-control--done
    .form-control__button-svg {
    font-size: var(--font-small) !important;
    font-family: roboto-regular !important;
}

html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store p,
html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store .ec-text-block {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store .ec-cart-email,
html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--l
    .ec-store
    .ec-cart-email__text {
    font-family: roboto-regular !important;
    font-size: var(--font-vsmall) !important;
}
.html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .form-control--primary
    .form-control__loader {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--l
    .ec-store
    .ec-cart-item__title {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html body#ecwid_body .ec-size .ec-store .ec-link,
html#ecwid_html body#ecwid_body .ec-size .ec-store .ec-link:visited {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}
html#ecwid_html body#ecwid_body .ec-size.ec-size--l .ec-store .ec-cart-next {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size.ec-size--l
    .ec-store
    .ec-cart-shopping__wrap {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html body#ecwid_body .ec-size .ec-store .ec-cart-shopping .ec-link {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html body#ecwid_body .ec-size .ec-store .form-control {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html body#ecwid_body .ecsp div {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .grid-product__wrap-inner
    > *:last-child:not(.grid-product__image),
html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .grid__products
    .grid-product__image
    ~ .grid-product__title,
html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .grid__products--medium-items
    .grid-product__title-inner {
    font-family: roboto-bold !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html body#ecwid_body .ec-size .ec-store .form-control--button {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

.html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .details-product-purchase--checkout.details-product-purchase--add-more
    .details-product-purchase__controls {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .form-control--animated.form-control--done
    .form-control__button-text,
html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .form-control--animated.form-control--done
    .form-control__button-svg {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}
html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .form-control:not(.form-control--loading)
    .form-control__button {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html body#ecwid_body .ec-size .ec-store h5,
html#ecwid_html body#ecwid_body .ec-size .ec-store .ec-header-h5 {
    font-family: roboto-regular !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .grid__products--medium-items
    .grid-product__price-hover
    .grid-product__price-amount,
html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .grid__products--medium-items
    .grid-product__image
    ~ .grid-product__price
    .grid-product__price-amount,
html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .grid__products--medium-items
    .grid-product__image
    ~ .grid-product__title
    .grid-product__price-amount {
    font-family: "roboto-regular" !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html body#ecwid_body .ecwid a {
    font-family: "roboto-regular" !important;
    font-size: var(--font-small) !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-cart__sidebar
    .ec-cart-item__count--select
    * {
    font-family: "roboto-regular" !important;
    font-size: var(--font-vsmall) !important;
}
@media (min-width: 767px) {
    .content.store {
        padding-top: 2em;
    }
}

html#ecwid_html body#ecwid_body .product-details-module__title.ec-header-h6 {
    display: none !important;
}

html#ecwid_html
    body#ecwid_body
    .ec-size
    .ec-store
    .form-control:not(.form-control--loading)
    .form-control__button {
    background-color: var(--color-yellow) !important;
    color: var(--color-pewter) !important;
}
