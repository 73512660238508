.terms-content p,
.terms-content ul,
.terms-content h4,
.terms-content h1,
.terms-content h2,
.terms-content h3,
.terms-content h5,
.terms-content h6
{
    margin-left: 10vw;
    margin-right: 10vw;
}

.terms-content img{
    border: 1px solid var(--color-pewter);
    max-width: 80vw;
}


.float-image img{
    float:left;
    margin-right: 2vw;
    max-width: 40vw;
   
}

.float-image::after{
    display: block;
    clear:both;
    content: " ";
    width: 100vw;

}


@media (max-width: 767px){
    .terms-content p,
    .terms-content ul,
    .terms-content h4,
    .terms-content h1,
    .terms-content h2,
    .terms-content h3,
    .terms-content h5,
    .terms-content h6{
        margin-left: 3vw;
        margin-right: 3vw;
    }

  
}


@media (min-aspect-ratio: 180/100) {
    .terms-content{
        width: 80%;
        margin:auto;
    }
    
}