#mailchimp{
    position: fixed;
    top: 10%;
    left: 50vw;
    transform: translate(-50%, 0);
    width: 45vw;
    height: 800px;
    margin:auto;
    opacity: 1 !important;
    z-index: 20;
    overflow:none;
    border:none;

}

.model-bg{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-pewter);
    opacity: .9;
    z-index: 19;
   
}

.model-close{
    position: fixed;
    top: 10%;
    left: calc(50vw + (45vw/2) + (49px/2));
    text-align: right;
    transform: translate(-50%, 0);
    background-color: #cccccc;
    z-index: 20;
    line-height: 49px;
    text-align: center;
    width: 48px;
    width: 48px;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    padding-top: 4px;
    
}


.model-colse:before{
    content: "\f00d";
}

.model-close:hover{
    background-color: var(--color-red);
}

@media (max-width: 767px){

    #mailchimp{
        position: fixed;
        top: 0%;
        left: 0;
        transform:none;
        width: 100vw;
        height: 100vh;

    }

    .model-close{
        position: fixed;
        top: 0;
        right: 0;
        left: calc(100vw - 48px);
        transform: none;
        border-radius: 3px 3px 3px 3px;
        
        
    }
}

