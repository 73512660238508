.press-itmes {
    display: flex; 
    flex-wrap: wrap;
    padding: none;
    padding: 0;
    padding-left: 9vw;
    padding-right: 5vw;
}

.press-item {
    width: 26vw;
    height: 26vw;
    margin-right: 2vw;
    margin-bottom: 2vw;
    margin-left: none;
}

.press-item img{
    width: 26vw;
    height: 26vw;
    object-fit: scale-down;
}

.press-content p,
.press-content ul,
.press-content h4,
.press-content h1,
.press-content h2,
.press-content h3,
.press-content h5,
.press-content h6,
.press-images 
{
    margin-left: 9vw;
    margin-right: 9vw;
}

.press-images{
    display: flex; 
    flex-wrap: wrap;
    margin-right: 7vw;
    justify-content: space-evenly;
}


.press-content img{
    margin-right: 2vw;
    margin-bottom: 2vw;
    max-width: 80vw;
    object-fit: scale-down;
    
}

@media (max-width: 767px){

.press-content p,
.press-content ul,
.press-content h4,
.press-content h1,
.press-content h2,
.press-content h3,
.press-content h5,
.press-content h6,
.press-images 
{
    margin-left: 3vw;
    margin-right: 3vw;
}


}


@media (min-aspect-ratio: 180/100) {
    .press{
        width: 80%;
        padding-left: 10%;
    }


    .press-item, .press-item img{ 
        width: 20vw;
        height: 20vw;;
    }

}